<template>
  <el-dialog title="详情" :visible.sync="dialogTableVisible" append-to-body>
    <commonTable
        :tableData="tableData"
        :total="total"
        :currentPage="pageNum"
        @handleSizeChange="handleSizeChange"
        @handleCurrentChange="handleCurrentChange"
    >
      <template v-slot:table>
        <el-table-column
            prop="className"
            align="center"
            label="产品类别"
        />
        <el-table-column
            prop="handle"
            align="center"
            label="标识"
            width="230"
        >
          <template slot-scope="scope">
            {{ scope.row.handle }}
            <el-tag>{{ scope.row.type == 3 ? '箱' : '盒' }}</el-tag>
          </template>
        </el-table-column>
        <el-table-column
            prop="warehouseName"
            align="center"
            label="仓库"
        />
        <el-table-column
            prop="code"
            align="center"
            label="代号"
            v-if="wareStatus == 2"
        />
        <el-table-column
            prop="nickName"
            align="center"
            label="入库人"
            v-if="wareStatus == 1"
        />
        <el-table-column
            prop="nickName"
            align="center"
            label="出库人"
            v-else
        />
        <el-table-column
            prop="createTime"
            align="center"
            label="入库时间"
            v-if="wareStatus == 1"
        />
        <el-table-column
            prop="createTime"
            align="center"
            label="出库时间"
            v-else
        />
      </template>
    </commonTable>

  </el-dialog>
</template>

<script>
import commonTable from "@/components/common/commonTable";
import { warehouseLogDetail} from '@/api/warehouseManage.js'

export default {
  name: "dealerList",
  components: {
    commonTable,
  },
  data() {
    return {
      tableData: [],
      pageSize: 10,
      pageNum: 1,
      total: 0,
      parentId:null,
      dialogTableVisible:false,
      wareStatus:null,
      parentData:''
    };
  },
  created() {

  },
  methods: {
    init(data,type){
      this.parentData = data
      this. pageNum = 1
      this.total = 0
      this.tableData = []
      this.parentId = this.parentData.pageHandleId
      this.wareStatus = type
      this.getList()
    },
    handleSizeChange(num) {
      this.pageSize = num;
      this.pageNum = 1;
      this.getList();
    },
    handleCurrentChange(num) {
      this.pageNum = num;
      this.getList();
    },
    handleAddDealer(type, id) {
      this.$refs.addDealerDialog.openDialog(type, id)
    },
    getList() {
      let params = {
        pageNum: this.pageNum,
        pageSize: 10,
        pageHandleId: this.parentId
      }
      warehouseLogDetail(params).then(res => {
        this.tableData = res.data.list
        this.tableData.forEach(item=>{
          item.warehouseName = this.parentData.warehouseName
          item.nickName = this.parentData.nickName
          item.createTime=this.parentData.createTime
          item.code = this.parentData.dealerCode
        })
        this.total = res.data.total
      })
    },
  }
}
</script>

<style lang="scss" scoped></style>

