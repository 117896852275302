<template>
  <comm-dialog title="溯源信息" ref="dialog" :show-fotter="false" >
    <template slot="content">
      <el-timeline class="contentMain">
        <el-timeline-item
            v-for="(item, index) in lineDataList"
            :key="index"
            :timestamp="item.createTime">
          <el-tag type="info" v-if="item.revokeStatus == 1">已撤销</el-tag>
          <span v-if="item.wareStatus===1">入库: {{item.warehouseName}}</span>
          <span v-else-if="item.wareStatus===2">出库: {{item.dealerName}}</span>
          <span v-else>退货</span>
          <span>操作人: {{item.createBy}}</span>
          <span v-if="item.handle&&item.isBox!==isBox">
              {{item.handle}}<span :class="item.isBox ? 'box' : 'pack'" style="padding-right: 0;">{{item.isBox ? '箱' : '盒' }}</span>
          </span>
        </el-timeline-item>
      </el-timeline>
    </template>
  </comm-dialog>
</template>
<script>
import {get} from "@/utils/request";
import CommDialog from "@/components/common/CommDialog.vue";

export  default {
  components: {CommDialog},
   data(){
     return {
       lineDataList: [],
       dialogShow: false,
       isBox: true
     }
   },
  methods:{
    show(handleId){
      this.isBox=false;
      this.initList(handleId)
    },
    initList(handleId){
      get('/crk/app/pageLog',{handleId:handleId}).then(res=>{
        this.lineDataList=res;
        this.$refs.dialog.dialogShow=true
      })
    },
    showBox(handleId){
      this.isBox=true;
      this.$refs.dialog.dialogShow=true
      get('/crk/app/boxLog',{boxId:handleId}).then(res=>{
        this.lineDataList=res;
      })
    },

  }
}
</script>
<style scoped>
.contentMain span {
  padding-right: 10px;
}
</style>
