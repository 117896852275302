import httpUtil from "@/utils/httpUtil";

export const selectWarehouseList = params => httpUtil.get("/warehouse/page", params);


export const addWarehouse = params => httpUtil.post("/warehouse/addOrUpdate", params);

export const deleteWarehouse = params => httpUtil.post("/warehouse/delete", params);

export const warehouseLogPage = params => httpUtil.get("/crk/app/warehouseLog", params);

export const fetchCrkBatchList = params => httpUtil.get("/crk/app/crkBatchPage", params);

export const fetchUnbindList = params => httpUtil.get("/crk/app/unbindPage", params);
export const fetchUnbindDetail = params => httpUtil.get("/crk/app/getUnbindDetail", params);

export const fetchCrkBackList = params => httpUtil.get("/crk/app/crkBackPage", params);

export const fetchCrkBackDetail = params => httpUtil.get("/crk/app/getBackDetail", params);

export const fetchRevoke = params =>  httpUtil.post("/crk/app/revoke", params)


export const warehouseLogDetail = params => httpUtil.get("/crk/app/packList", params);

export const operatorPage = params => httpUtil.get("/crk/app/getOperator", params);
export const addOperator = params => httpUtil.get("/crk/app/addOperator", params);
export const productPage = params => httpUtil.get("/crk/app/productPage", params);
export const getAllWarehouse = params => httpUtil.get("/crk/app/getWarehouse", params);
export const productDetailPage = params => httpUtil.get("/crk/app/productDetailPage", params);

export const getAllPageClass = params => httpUtil.get("/crk/app/getPageClass", params);

export const deleteOperator = params => httpUtil.post("/crk/app/deletOperator", params);
export const getDealer = params => httpUtil.get("/crk/app/getDealer", params);

export const getCrkConf = params => httpUtil.get("/crk/conf", params);

export const saveCrkConf = params => httpUtil.post("/crk/conf", params);

export const getWaitReceivePage = params => httpUtil.get("/crk/app/waitReceivePage", params);

// 出库订单列表
export const fetchCkOrderList = params => httpUtil.get("/crk/order", params);
// 出库订单删除
export const fetchDeleteCkOrder = params => httpUtil.post("/crk/order/delete", params);
// 添加出库订单
export const fetchAddCkOrder = params => httpUtil.post("/crk/order", params)
// 订单详情
export const fetchCkOrderDetail = params => httpUtil.get("/crk/order/detailPage", params)
// 出库入库任务记录
export const fetchCrkHandleRecord = params => httpUtil.get("/crk/produce/getRecord", params);
// 出库任务记录
export const fetchCkHandleRecord = params => httpUtil.get("/crk/order/getRecord", params);

export const fetchCrkProduceDetail = params => httpUtil.get("/crk/produce/getProduceDetail", params)

export const fetchCkProduceList = params => httpUtil.get("/crk/produce", params);

export const fetchAddCrkProduce = params => httpUtil.post("/crk/produce", params)

export const selectAllWarehouse = params => httpUtil.get("/warehouse/noPage", params);
export const selectProduceLotNumber = params => httpUtil.get("/crk/produce/getLotNumber", params);
export const selectOrderLotNumber = params => httpUtil.get("/crk/order/getLotNumber", params);

export const fetchDeleteCrkProduce = params => httpUtil.post("/crk/produce/delete", params);

export const fetchWarehouseTree = params => httpUtil.get("/crk/app/warehouseTree", params);

export const fetchGpsData = params => httpUtil.get("/gpsData", params);


export const pullWdtOrder = params => httpUtil.get("/crk/order/pullWdt", params);