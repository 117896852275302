<template>
  <el-dialog :visible.sync="dialogShow" append-to-body :title="title" :width="width">
    <slot name="content"></slot>
    <div slot="footer" class="dialog-footer" v-if="showFotter">
      <el-button @click="dialogShow=false">取 消</el-button>
      <el-button type="primary" @click="$emit('confirm')">确 定</el-button>
    </div>
  </el-dialog>
</template>
<script>
  export default {
    props:{
      title:{
        type: String,
        default: ''
      },
      width:{
        type: String,
        default: '40%'
      },
      showFotter:{
        type: Boolean,
        default: true
      }
    },
    data(){
      return {
        dialogShow: false
      }
    }
  }
</script>
